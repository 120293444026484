.headerRequirement{
  display: flex;

  position: sticky;
  vertical-align: middle;
  text-align: center;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(27, 15, 117);
  width: 100%;
  margin-top: 0rem;
  padding: 5px;
  /* border: 1px solid #d8d5d5;   */
  /* Created with https://www.css-gradient.com */
/* background: #8CB5D1;
background: -webkit-linear-gradient(top left, #8CB5D1, #8EB7CC);
background: -moz-linear-gradient(top left, #8CB5D1, #8EB7CC);
background: linear-gradient(to bottom right, #8CB5D1, #8EB7CC);} */
}
.icons {
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
}
.requirementDrawer{
  height: 90vh;

 
  vertical-align: top;
  text-align: center;
  display: grid;
  font-size: 1rem;
  font-weight: 500;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(27, 15, 117);
  width: 100%;
  margin-top: 0rem;
  padding: 15px;	
  background-color:azure;
  /* Created with https://www.css-gradient.com */
background: #F1F1F1;
background: -webkit-radial-gradient(top left, #F1F1F1, #E5E5E5);
background: -moz-radial-gradient(top left, #F1F1F1, #E5E5E5);
background: radial-gradient(to bottom right, #F1F1F1, #E5E5E5);
 
}
.ant-row .ant-form-item {
  margin-bottom: 6px;
} 
