.header {
  vertical-align: middle;
  text-align: center;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(255, 255, 255);

  margin-top: 0rem;
  padding: 1rem;
  background: #007991; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #78ffd6,
    #007991
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #78ffd6,
    #007991
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.icons {
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
}

.profile-body{

  height: 100vh;
  background: #F1F1F1;
  background: -webkit-radial-gradient(top left, #F1F1F1, #E5E5E5);
  background: -moz-radial-gradient(top left, #F1F1F1, #E5E5E5);
  background: radial-gradient(to bottom right, #F1F1F1, #E5E5E5);
}
.profile-side{
  display: grid;
  height: 100vh;
  background-color: #d9d9d9;
  background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
}

.profile-header{

 font-size: 2rem;

}.profile-font{

 font-size: 2rem;

}