.headerAppbar {
  z-index: 1000;
  position: sticky;
  vertical-align: middle;
  text-align: center;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(255, 255, 255);
width: 100%;
  margin-top: 0rem;
  padding: 5px;
  background: #007991; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #78ffd6,
    #007991
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #78ffd6,
    #007991
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-color: #ffffff;
background-image: linear-gradient(315deg, #E7EFF9 0%, #d7e1ec 74%);
}

.icons {
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
}

