.test-casecard {
    background-image: linear-gradient( 135deg, #90F7EC 10%, #32CCBC 100%);
}
.test-stepscard {
  background: hsla(263, 42%, 65%, 1);

  background: linear-gradient(
    90deg,
    hsla(263, 42%, 65%, 1) 0%,
    hsla(319, 77%, 86%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(263, 42%, 65%, 1) 0%,
    hsla(319, 77%, 86%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(263, 42%, 65%, 1) 0%,
    hsla(319, 77%, 86%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#9D80CB", endColorstr="#F7C2E6", GradientType=1 );
}
