@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
* {
  font-family: "Roboto";
}

.men {
  display: flex;
  list-style-type: none;

  justify-content: space-around;
}
.n {
  display: flex;
  color: rgb(26, 59, 88);
  background-color: rgb(240, 239, 239);
  justify-content: space-between;
}
li {
  display: block;
  padding: 15px;
  color: red;
  justify-content: space-between;
}
li:hover {
  display: block;
  padding: 15px;
  justify-content: space-between;
  background-color: rgb(204, 233, 242);
  color: rgb(242, 90, 90);
  transition: all .2s ease-out;
  font-weight: 600;
}

img {
  padding-left: 15px;
  width: 195px;
  height: 67px;
}
